import { Content } from 'pleinchamp-api-client/dist/src/content/search';
import React, { FC } from 'react';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { ContentCard } from '@content/ContentCard/ContentCard.component';
import { ContentCardHomeVersion } from '@content/ContentCardHomeVersion/ContentCardHomeVersion.component';
import { ContentCardJournalVersion } from '@content/ContentCardJournalVersion/ContentCardJournalVersion.component';
import { formatDateCompact, formatDateToIso } from '@utils/date';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import { PlcColor } from '@styles/colors';
import { Text } from '@components/Text/Text.component';

type Props = Omit<ContentCardProps, 'cardSubTitle' | 'cardActions' | 'href'> & {
  slug: string;
  date?: Date;
  newsHeadlines?: Content[];
};

const ActuCard: FC<Props> = ({ date, className, slug, componentVersion, index, newsHeadlines, ...rest }) => {
  const linkProps = getSlugLinkProps(urlEnum.actu, slug);
  const rootClass = classnames('', className);
  const cardSubTitle = date ? <time dateTime={formatDateToIso(date)}>{formatDateCompact(date)}</time> : null;
  //const cardActions = <TextWithArrow i18nKey="actu.card-action" tag="span" variant="p" />;

  const cardActions = (
    <><Text i18nKey="actu.card-action" tag="span" variant="h5" /><ChevronRightIcon
      className={'plc-mr-up-l-m plc-mr-up-m-s plc-ml-auto'}
      fillColor={PlcColor.GREEN}
      height={12}
      stroke={PlcColor.GREEN}
      width={12} /></>
  );

  switch (componentVersion) {
    case 'home-version':
      return (
        <ContentCardHomeVersion
          cardActions={cardActions}
          cardSubTitle={cardSubTitle}
          className={rootClass}
          index={index}
          newsHeadlines={newsHeadlines}
          {...linkProps}
          {...rest}
        />
      );
    case 'journal-version':
      return (
        <ContentCardJournalVersion
          cardSubTitle={cardSubTitle}
          className={rootClass}
          index={index}
          {...linkProps}
          {...rest}
        />
      );
    default:
      return (
        <ContentCard
          cardActions={cardActions}
          cardSubTitle={cardSubTitle}
          className={rootClass}
          {...linkProps}
          {...rest}
        />
      );
  }
};

export { ActuCard };
