import React, { FC } from 'react';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './ContentCardHomeVersion.module.scss';
import { ContentCardProps } from './ContentCardHomeVersion.types';

const ContentCardHomeVersion: FC<ContentCardProps> = ({
  cardImage,
  cardImageCentered = false,
  cardSubTitle,
  cardTitle,
  cardActions,
  cardHeaderTop,
  cardHeaderBottom,
  href,
  as,
  className,
  index,
  newsHeadlines,
  ...rest
}) => {
  const [animationState, setAnimationState] = React.useState(index ? 'paused' : 'running');

  React.useEffect(() => {
    if (!index) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const timeoutId = setTimeout(() => setAnimationState('running'), index * 150);
    return () => clearTimeout(timeoutId);
  });

  const isMobile = useIsBreakpointDown(Breakpoint.s);
  const newsLastItem = isMobile ? 2 : 1;
  const rootClass = classnames(styles.contentCard, 'flex align-center new-grid-m-2-s-2', animationState, className);
  const cardImageClass = classnames(styles.cardImage, { centered: cardImageCentered });
  const article = (
    <article
      className={rootClass}
      style={{
        borderBottom:
          index && newsHeadlines && index >= newsHeadlines.length - newsLastItem
            ? 'none'
            : `1px solid ${PlcColor.LIGHT_GREEN}`,
      }}
      {...rest}
    >
      <div>
        <Text className={`${styles['card-title']} plc-mb-up-m-m`} tag="p" variant={isMobile ? 'new-bold-p' : 'h7'}>
          {cardTitle}
        </Text>
        {!isMobile && (
          <div>
            <Text i18nKey="actu.card-action" tag="span" variant="h5" />
            <ChevronRightIcon fillColor={PlcColor.GREEN} height={12} stroke={PlcColor.GREEN} width={12} />
          </div>
        )}
      </div>
      {cardImage && <div className={cardImageClass}>{cardImage}</div>}
    </article>
  );

  if (!href) {
    return article;
  }

  return (
    <PlcAnchor linkProps={{ as, href }} niveau3={href.toString().includes(urlEnum.guides) ? cardTitle?.toString() : ''}>
      {article}
    </PlcAnchor>
  );
};

export { ContentCardHomeVersion };
