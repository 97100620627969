import React from 'react';
import { PlcColor } from '@styles/colors';
import { SquareSvgProps } from './icon.types';

function CarbonMapIcon({ size = 24, fillColor = PlcColor.BLACK, ...props }: SquareSvgProps) {
  return (
    <svg height={size} viewBox="0 0 24 24" width={size} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.6997 16.1565C12.3579 16.6383 11.6425 16.638 11.3011 16.1559L8.085 11.6143C7.44419 10.8577 7.03151 9.9346 6.89504 8.95253C6.75857 7.97046 6.90392 6.96983 7.31415 6.06717C7.72439 5.16451 8.38264 4.39698 9.21222 3.85397C10.0418 3.31095 11.0086 3.0148 12 3C13.3743 3.01358 14.6871 3.57124 15.6511 4.55084C16.615 5.53044 17.1514 6.85212 17.1429 8.22643C17.1434 9.42532 16.7352 10.5886 15.9857 11.5243L12.6997 16.1565ZM12 4.28571C10.9662 4.29759 9.97935 4.71924 9.25616 5.4581C8.53298 6.19695 8.13257 7.1926 8.14286 8.22643C8.14716 9.17003 8.48665 10.0814 9.10071 10.7979L12 14.9057L14.9764 10.7143C15.5422 10.0081 15.8526 9.13131 15.8571 8.22643C15.8674 7.1926 15.467 6.19695 14.7438 5.4581C14.0207 4.71924 13.0338 4.29759 12 4.28571Z"
        fill={fillColor}
      />
      <path
        d="M12 9.64286C12.7101 9.64286 13.2857 9.06722 13.2857 8.35714C13.2857 7.64706 12.7101 7.07143 12 7.07143C11.2899 7.07143 10.7143 7.64706 10.7143 8.35714C10.7143 9.06722 11.2899 9.64286 12 9.64286Z"
        fill={fillColor}
      />
      <path
        d="M19.7143 9.42857H19.0714C18.7164 9.42857 18.4286 9.71639 18.4286 10.0714C18.4286 10.4265 18.7164 10.7143 19.0714 10.7143H19.7143V19.7143H4.28571V10.7143H4.92857C5.28361 10.7143 5.57143 10.4265 5.57143 10.0714C5.57143 9.71639 5.28361 9.42857 4.92857 9.42857H4.28571C3.94472 9.42857 3.6177 9.56403 3.37658 9.80515C3.13546 10.0463 3 10.3733 3 10.7143V19.7143C3 20.0553 3.13546 20.3823 3.37658 20.6234C3.6177 20.8645 3.94472 21 4.28571 21H19.7143C20.0553 21 20.3823 20.8645 20.6234 20.6234C20.8645 20.3823 21 20.0553 21 19.7143V10.7143C21 10.3733 20.8645 10.0463 20.6234 9.80515C20.3823 9.56403 20.0553 9.42857 19.7143 9.42857Z"
        fill={fillColor}
      />
      <path
        d="M12.6997 16.1565C12.3579 16.6383 11.6425 16.638 11.3011 16.1559L8.085 11.6143C7.44419 10.8577 7.03151 9.9346 6.89504 8.95253C6.75857 7.97046 6.90392 6.96983 7.31415 6.06717C7.72439 5.16451 8.38264 4.39698 9.21222 3.85397C10.0418 3.31095 11.0086 3.0148 12 3C13.3743 3.01358 14.6871 3.57124 15.6511 4.55084C16.615 5.53044 17.1514 6.85212 17.1429 8.22643C17.1434 9.42532 16.7352 10.5886 15.9857 11.5243L12.6997 16.1565ZM12 4.28571C10.9662 4.29759 9.97935 4.71924 9.25616 5.4581C8.53298 6.19695 8.13257 7.1926 8.14286 8.22643C8.14716 9.17003 8.48665 10.0814 9.10071 10.7979L12 14.9057L14.9764 10.7143C15.5422 10.0081 15.8526 9.13131 15.8571 8.22643C15.8674 7.1926 15.467 6.19695 14.7438 5.4581C14.0207 4.71924 13.0338 4.29759 12 4.28571Z"
        stroke={fillColor}
        strokeWidth="0.5"
      />
      <path
        d="M12 9.64286C12.7101 9.64286 13.2857 9.06722 13.2857 8.35714C13.2857 7.64706 12.7101 7.07143 12 7.07143C11.2899 7.07143 10.7143 7.64706 10.7143 8.35714C10.7143 9.06722 11.2899 9.64286 12 9.64286Z"
        stroke={fillColor}
        strokeWidth="0.5"
      />
      <path
        d="M19.7143 9.42857H19.0714C18.7164 9.42857 18.4286 9.71639 18.4286 10.0714C18.4286 10.4265 18.7164 10.7143 19.0714 10.7143H19.7143V19.7143H4.28571V10.7143H4.92857C5.28361 10.7143 5.57143 10.4265 5.57143 10.0714C5.57143 9.71639 5.28361 9.42857 4.92857 9.42857H4.28571C3.94472 9.42857 3.6177 9.56403 3.37658 9.80515C3.13546 10.0463 3 10.3733 3 10.7143V19.7143C3 20.0553 3.13546 20.3823 3.37658 20.6234C3.6177 20.8645 3.94472 21 4.28571 21H19.7143C20.0553 21 20.3823 20.8645 20.6234 20.6234C20.8645 20.3823 21 20.0553 21 19.7143V10.7143C21 10.3733 20.8645 10.0463 20.6234 9.80515C20.3823 9.56403 20.0553 9.42857 19.7143 9.42857Z"
        stroke={fillColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export { CarbonMapIcon };
