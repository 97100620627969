/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useBreakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './ContentCardJournalVersion.module.scss';
import { ContentCardJournalVersionProps } from './ContentCardJournalVersion.types';

function substringText(text: string) {
  let valueTruncate: undefined | number;
  switch (useBreakpoint()) {
    case Breakpoint.s:
      valueTruncate = 52;
      break;
    case Breakpoint.xs:
      valueTruncate = 48;
      break;
    default:
      valueTruncate = undefined;
  }
  if (valueTruncate) {
    return text.length > valueTruncate ? `${text.substring(0, valueTruncate - 1)}...` : text;
  }
  return text;
}

const ContentCardJournalVersion: FC<ContentCardJournalVersionProps> = ({
  cardImage,
  cardSubTitle,
  cardTitle,
  href,
  as,
  className,
  index,
  ...rest
}) => {
  const [animationState, setAnimationState] = React.useState(index ? 'paused' : 'running');
  const isMobile = useIsBreakpointDown(Breakpoint.m);

  React.useEffect(() => {
    if (!index) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const timeoutId = setTimeout(() => setAnimationState('running'), index * 150);
    return () => clearTimeout(timeoutId);
  });

  const rootClass = classnames(styles.contentCard, 'flex align-center new-grid-m-2-s-2', animationState, className);
  const article = (
    <article className={rootClass} {...rest}>
      {cardImage && <div className={styles.cardImage}>{cardImage}</div>}
      <div className="align-center">
        {cardSubTitle && (
          <Text className={styles['card-subtitle']} tag="p" variant={isMobile ? 'tiny-title' : 'date'}>
            {cardSubTitle}
          </Text>
        )}
        <Text className={styles['card-title']} tag="p" variant={isMobile ? 'new-bold-p' : 'new-h3'}>
          {substringText(cardTitle as string)}
        </Text>
      </div>
    </article>
  );

  if (!href) {
    return article;
  }

  return (
    <PlcAnchor linkProps={{ as, href }} niveau3={href.toString().includes(urlEnum.guides) ? cardTitle?.toString() : ''}>
      {article}
    </PlcAnchor>
  );
};

export { ContentCardJournalVersion };
