import { Address } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { HTMLPropsHeaders, TextProps } from '@components/Text/Text.types';
import { CarbonMapIcon } from '@icons/CarbonMap.icon';
import classnames from 'classnames';

export enum EventLocationVariant {
  normal = 'normal',
  small = 'small',
  tiny = 'tiny',
}

type Props = HTMLPropsHeaders & {
  address: Address;
  variant?: EventLocationVariant;
};

const EventLocation: FC<Props> = ({ address, variant = EventLocationVariant.normal, className, ...rest }) => {
  let location = address.country;

  if (address.countryCode.toLowerCase() === 'fr' && address.stateOrProvince) {
    location = address.stateOrProvince;

    if (address.stateOrProvinceCode) {
      location += ` (${address.stateOrProvinceCode})`;
    }
  }

  let iconSize;
  let textVariant: TextProps['variant'];
  switch (variant) {
    case EventLocationVariant.small:
      iconSize = 18;
      textVariant = 'h6';
      break;
    case EventLocationVariant.tiny:
      iconSize = 16;
      textVariant = 'date';
      break;
    default:
      iconSize = 24;
      textVariant = 'new-p';
  }
  const rootClass = classnames('event-location flex align-center white-space-nowrap', className);

  return (
    <Text className={rootClass} tag="span" variant={textVariant} {...rest}>
      <CarbonMapIcon className="plc-mr-xxs" size={iconSize} />
      {location}
    </Text>
  );
};

export { EventLocation };
