import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { PlcImgLoader } from '@components/Loaders/PlcImgLoader.component';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import {PlcColor} from "@styles/colors";

interface Props {
  title: string;
  date: string[];
  fillColor: CSSProperties['fill'];
}

export default function GeneratedEventImage({ title, date, fillColor }: Props) {
  const isMobile = useIsBreakpointDown(Breakpoint.m);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const generateImage = async () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      canvas.width = isMobile ? 120 : 150;
      canvas.height = 105;
      ctx.fillStyle = fillColor as string;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = PlcColor.WHITE;
      ctx.font = isMobile ? 'bold 14px Montserrat, sans-serif' : 'bold 16px Montserrat, sans-serif';
      ctx.fillText(title.toUpperCase(), 10, 30);

      ctx.fillStyle = PlcColor.YELLOW_SUN;
      date.forEach((d, index) => {
        ctx.fillText(d.toLowerCase(), 10, 50 + index * 20);
      });

      const dataUrl = canvas.toDataURL('image/png');
      setImageSrc(dataUrl);
      setIsLoading(false);
    };

    generateImage();
  }, [title, date, fillColor]);

  return (
    <>
      {isLoading && <PlcImgLoader uniqueKey="event-img" width={180} />}

      {!isLoading && imageSrc && (
        <img alt="event-img" src={imageSrc} style={{ backgroundColor: fillColor, objectFit: 'none' }} />
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
}
