import { GuideEpisodesHeader } from '../GuideEpisodesHeader/GuideEpisodesHeader.component';
import { Content } from 'pleinchamp-api-client';
import React, { FC } from 'react';
import { ContentCard } from '@components/content/ContentCard/ContentCard.component';
import { ContentCardProps } from '@components/content/ContentCard/ContentCard.types';
import { ContentCardJournalVersion } from '@components/content/ContentCardJournalVersion/ContentCardJournalVersion.component';
import { TextWithArrow } from '@components/Text/TextWithArrow.component';
import { ContentCardThematicVersion } from '@content/ContentCardThematicVersion/ContentCardThematicVersion.component';
import { getSlugLinkProps, urlEnum } from '@utils/url';
import classnames from 'classnames';

type Props = Omit<ContentCardProps, 'cardSubTitle' | 'cardActions' | 'href'> & {
  slug: string;
  episodesCount: number;
  guide: Content;
};

const GuideCard: FC<Props> = ({ title, slug, episodesCount, className, componentVersion, guide, ...rest }) => {
  const linkProps = getSlugLinkProps(urlEnum.guides, slug);
  const rootClass = classnames('guide-card', className);
  const cardSubTitle = <GuideEpisodesHeader count={episodesCount} />;
  const cardActions = <TextWithArrow i18nKey="guides.link" tag="span" variant="p" />;

  switch (componentVersion) {
    case 'journal-version':
      return (
        <ContentCardJournalVersion
          cardActions={cardActions}
          cardSubTitle={cardSubTitle}
          className={rootClass}
          {...linkProps}
          {...rest}
        />
      );
    case 'thematic-version':
      return <ContentCardThematicVersion guide={guide} linkProps={linkProps} />;
    default:
      return (
        <ContentCard
          cardActions={cardActions}
          cardSubTitle={cardSubTitle}
          className={rootClass}
          {...linkProps}
          {...rest}
        />
      );
  }
};

export { GuideCard };

