/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useContext } from 'react';
import { Text } from '@components/Text/Text.component';
import { ContentCardThematicVersionProps } from '@content/ContentCardThematicVersion/ContentCardThematicVersion.types';
import { ChevronRightIcon } from '@icons/ChevronRight.icon';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { Breakpoint, useBreakpoint } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { GeneralContext } from 'features/business/app.contexts';
import styles from './ContentCardThematicVersion.module.scss';

function substringText(text: string) {
  const { windowWidth } = useContext(GeneralContext);
  let valueTruncate: undefined | number;
  if (windowWidth === Breakpoint.m) {
    valueTruncate = 245;
  } else {
    switch (useBreakpoint()) {
      case Breakpoint.s:
      case Breakpoint.m:
      case Breakpoint.l:
        valueTruncate = 250;
        break;
      default:
        valueTruncate = 350;
    }
  }
  if (valueTruncate) {
    return text.length > valueTruncate ? `${text.substring(0, valueTruncate - 1)}...` : text;
  }
  return text;
}

export const ContentCardThematicVersion: FC<ContentCardThematicVersionProps> = ({ guide, linkProps }) => {
  const { title } = guide;
  const { description } = guide.guideDetail!;
  return (
    <PlcAnchor linkProps={linkProps} niveau3={title}>
      <article className={styles.content}>
        <Text alignment="center" className={styles.top} i18nKey={title} tag="p" variant="h7" />
        {description && (
          <Text className={styles.middle} variant="small">
            {substringText(description)}
          </Text>
        )}
        <div className={styles.bottom}>
          <Text className="plc-mr-xs" i18nKey="thematic.guide.action" tag="span" variant="h5" />
          <ChevronRightIcon fillColor={PlcColor.GREEN} height={12} stroke={PlcColor.GREEN} width={12} />
        </div>
      </article>
    </PlcAnchor>
  );
};
